.delete-user-container {
    padding: 32px;
    background-color: #ffffff;
    width: 100%;
}

.delete-message {
    font-size: 16px;
    text-align: center;
    color: red;
    margin-bottom: 32px;
}

.delete-message-data {
    color: green;
    font-size: 20px;
}

/* ปรับให้ input ชิดกับเนื้อหา และไม่มีสีเทาเกินขอบ */
.delete-user-container input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 0; /* เอาส่วนที่ทำให้สีเทาติดข้างบนออก */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff; /* ป้องกันสีเทาเกิน */
}
