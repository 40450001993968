* {
  font-family: "Prompt", sans-serif;
}

.popupJoinChallenge {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .display_name {
    margin-left: -10px !important;
    margin-right: -30px !important;
  }
  .li {
    margin-left: -20px !important;
  }
  .bottom-search {
    margin-top: 20px;
  }
  .box-Individual {
    max-height: 500px !important;
  }
  .icon_web {
    padding-left: 8px !important;
  }
  .icon_web2 {
    padding-left: 8px !important;
  }
  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (max-width: 667px) {
  .popupJoinChallenge {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .display-btn {
    display: none;
  }
  .canterMode-box {
    margin-top: 50px !important;
  }
  .icon_rank-img {
    position: relative;
    margin-top: 5px !important;
    margin-bottom: 20px;
  }

  .icon_web {
    margin-left: -94.5% !important;
    margin-top: 2px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 1px;
  }
  .icon_web2 {
    margin-left: -94.5% !important;
    margin-top: 2px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 1px;
  }
}
@media only screen and (min-width: 992px) {
  .line-hr {
    display: none;
  }
  .bottom-shareBox {
    display: none;
  }
}

.overlayContainerPopupJoinChallenge {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupJoinChallenge.active {
  display: block;
}

.popupJoinChallenge.active {
  display: block;
}

.popupRulesAndPrizes {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupRulesAndPrizes {
    position: absolute;
    top: 100%;
    width: 70%;
  }
}

.overlayContainerPopupRulesAndPrizes {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupRulesAndPrizes.active {
  display: block;
}

.popupRulesAndPrizes.active {
  display: block;
}

.popupScoreDetail {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupScoreDetail {
    position: absolute;
    top: 100%;
    width: 70%;
  }
}

.overlayContainerPopupScoreDetail {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupScoreDetail.active {
  display: block;
}

.popupScoreDetail.active {
  display: block;
}

.popupMaxFriendsDetail {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupMaxFriendsDetail {
    position: absolute;
    top: 100%;
    width: 70%;
  }
}

.overlayContainerPopupMaxFriendsDetail {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupMaxFriendsDetail.active {
  display: block;
}

.popupMaxFriendsDetail.active {
  display: block;
}

.popupFriendRequest {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupFriendRequest {
    position: absolute;
    top: 100%;
    width: 70%;
  }
}

.overlayContainerPopupFriendRequest {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupFriendRequest.active {
  display: block;
}

.popupFriendRequest.active {
  display: block;
}

.popupTeamInvite {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupTeamInvite {
    position: absolute;
    top: 100%;
    width: 70%;
  }
}

.overlayContainerPopupTeamInvite {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupTeamInvite.active {
  display: block;
}

.popupTeamInvite.active {
  display: block;
}

.popupDeleteFriend {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupDeleteFriend {
    position: absolute;
    top: 100%;
    width: 70%;
  }
}

.overlayContainerPopupDeleteFriend {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupDeleteFriend.active {
  display: block;
}

.popupDeleteFriend.active {
  display: block;
}

.popupLeaveTeam {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 25px;
  padding: 1.5%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 667px) {
  .popupLeaveTeam {
    width: 80%;
  }
}

.overlayContainerPopupLeaveTeam {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
}

.overlayContainerPopupLeaveTeam.active {
  display: block;
}

.popupLeaveTeam.active {
  display: block;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: #ed0876;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}
.box-Individual {
  height: "auto";
  max-height: 400px;
  overflow: scroll;
  margin-bottom: 10px;
}
.user-idLogin {
  background-color: rgba(155, 155, 155, 0.467);
  padding: 5px;
  border-radius: 4px;
}
.btn-achievement {
  background-color: #ed0876;
  width: 80px;
  height: 40px;
  color: #ffff;
  border-color: #ed0876;
  border-radius: 50px !important;
  font-weight: bold;
  font-size: 18px;
}
.btn-achievement:hover {
  background-color: #ed0876;
  width: 80px;
  height: 40px;
  color: #ffff;
  border-color: #ed0876 !important;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25) !important;
  border-radius: 50px;
  font-weight: bold;
  font-size: 18px;
}
.btn-achievement:focus {
  background-color: #ed0876;
  width: 80px;
  height: 40px;
  color: #ffff;
  border-color: #ed0876 !important;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25) !important;
  border-radius: 50px;
  font-weight: bold;
  font-size: 18px;
}
.modal-subscription {
  margin-top: -53px;
  margin-left: 0px;
  position: relative;
}
.modal-subscription2 {
  margin-top: -55px;
  margin-left: 0px;
  position: relative;
}
.icon-share {
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}
.frame40 {
  width: 100%;
  height: 100%;
}
.frame41 {
  width: 100%;
}
.icon_web {
  position: absolute;
  z-index: 1;
  margin-left: -62px;
  margin-top: 2px;
  border-bottom-left-radius: 10px;
}
.icon_web2 {
  position: absolute;
  z-index: 1;
  margin-left: -51px;
  margin-top: 4px;
  border-bottom-left-radius: 10px;
}
.share-success {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  font-family: "Prompt";
}
.box-share {
  position: relative;
  border: 1px solid #ed0876;
  width: 200px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  height: 70px;
  margin-bottom: 40px;
  border-radius: 100px;
}
.modeText-box {
  font-size: 24px;
  color: #ed0876;
  font-weight: bold;
  font-family: "Prompt";
}
.canterMode-box {
  margin-top: 105px;
  text-align: center;
  display: inline-block;
  padding-right: 10px;
  font-family: "Prompt";
}
.modal-headerShare {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.btn-close {
  cursor: pointer;
  margin-top: -5px;
  z-index: 2;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  border: none !important;
  border-radius: 50px;
  margin-right: 10px;
}

.btn-close:focus {
  background-color: rgb(255, 255, 255) !important;
  color: #ed0876 !important;
  border: none !important;
}
.center {
  display: flex !important;
  justify-content: center !important;
}
.center2 {
  text-align: center;
  display: inline-block;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #000000;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  margin-top: -10px;
}
.bottom-share {
  margin-top: 20px;
  font-size: 18px;
  background-color: #ed0876;
  height: 40px;
  border-color: #ed0876;
  color: #ffffff;
  margin-bottom: 50px;
  border-radius: 25px !important;
  width: 160px;
  font-weight: bold;
}
.bottom-share:hover {
  background-color: #ffffff;
  border-color: #ed0876;
  color: #ed0876;
  margin-bottom: 50px;
  border-radius: 25px;
}
.bottom-share:focus {
  background-color: #ffffff;
  border-color: #ed0876;
  color: #ed0876;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25) !important;
  margin-bottom: 50px;
  border-radius: 25px;
}
.box-shareMobile {
  margin-top: -10px;
  position: relative;
  border: 1px solid #ed0876;
  width: 200px;
  padding-top: 13px;
  padding-left: 20px;
  /*  padding-right: 20px; */
  height: 65px;
  margin-bottom: 40px;
  border-radius: 100px;
}
.icon-share {
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}
.copy-link {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}
.button-copyLink {
  background-color: #ffffff;
  border-color: #d9d9d9;
  height: 39px !important;
  width: 50px !important;
}
.button-copyLink:focus {
  background-color: #ffffff;
  /*   border-color: #D9D9D9 !important; */
  height: 39px !important;
  width: 50px !important;
  border: 2px solid hsl(335, 53%, 70%) !important;
}
.copyLink-img {
  margin-top: -20px;
  margin-left: -7px;
}
/* .copyLink-img:focus {
  margin-top: -15px !important;
  margin-left: -7px;
background-color: #ED0876;
} */
.offcanvas-index {
  margin-top: -40px !important;
}
.share-to {
  color: #ed0876;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px !important;
}
.line-hr {
  margin-top: 0px;
}
.icon_rank-img {
  position: relative;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.show:not(.hiding),
  .offcanvas-md.showing {
    transform: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.hiding,
  .offcanvas-md.show,
  .offcanvas-md.showing {
    visibility: visible;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.show:not(.hiding),
  .offcanvas-lg.showing {
    transform: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.hiding,
  .offcanvas-lg.show,
  .offcanvas-lg.showing {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.show:not(.hiding),
  .offcanvas-xl.showing {
    transform: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.hiding,
  .offcanvas-xl.show,
  .offcanvas-xl.showing {
    visibility: visible;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.show:not(.hiding),
  .offcanvas-xxl.showing {
    transform: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show,
  .offcanvas-xxl.showing {
    visibility: visible;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
/** 
*! ข้างบนได้ เเล้ว  */

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
/**
*! ตัวที่ไม่ได้  */

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  /* margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5) */
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

/**
*! ล่างได้เเล้ว  */

.navbar-expand-sm .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand-sm .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand-sm .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
/**
*!  ได้  */

.row2 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row2 > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row2-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-webkit-inner-spin-button {
  height: auto;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

.display_name {
  margin-left: -30px !important;
  padding: 20px;
  border-radius: 25px !important;
}
.li {
  list-style: none !important;
  margin-top: 30px;
  line-height: 50px !important;
}
.bottom-search {
  background-color: #ed0876;
  height: 38px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px !important;
  font-size: 16px !important;
  /*   width: 80px; */
}
.bottom-search:hover {
  background-color: #ed0876;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px;
  font-size: 16px !important;
}
.bottom-search:focus {
  background-color: #ed0876;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px;
  font-size: 16px !important;
}
.bottom-cancel {
  margin-left: 10px;
  background-color: #ed0876;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px !important;
  font-size: 16px !important;
  /*  width: 80px; */
}
.bottom-cancel:hover {
  margin-left: 10px;
  background-color: #ed0876;
  height: 40px;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25);
}
.bottom-cancel:focus {
  margin-left: 10px;
  background-color: #ed0876;
  height: 40px;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25);
}
.bottom-add {
  /*  margin-left: 10px; */
  background-color: #ed0876;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: #ed0876;
  color: #ffffff;
  border-radius: 25px !important;
  font-size: 16px !important;
  /* width: "100%"; */
}
.bottom-add:hover {
  background-color: #ed0876;
  height: 40px;
  border-color: #ed0876;
  color: #ffffff;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25);
}
.bottom-add:focus {
  background-color: #ed0876;
  height: 40px;
  border-color: #ed0876;
  color: #ffffff;
  box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25);
}
.user_all {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  font-family: Prompt;
}
.top-search {
  margin-top: 20px;
}
.ellipse24 {
  padding-top: 60px;
  text-align: center;
  padding-left: 10px;
}
.text-noSystem {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  font-family: Prompt;
  margin-bottom: 20px;
}

.button_pink {
  background-color: #ed0876;
  border: none;
  border-radius: 50px;
  padding: 11px 16px;
  color: white;
}
