.box-reset-data {
    padding: 32px;
    
    background-color:  #ffffff;
    width: 100%;
    
}
.message-delete-data {
    color: green;
    font-size: 20px;
}
.message-delete {
    font-size: 16px;
    text-align: center;
    color: red;
    margin-bottom: 32px;
}