
.popupTrialRegister {
  display: none;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2%;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
  z-index:2;

    .text {
      font-size: 35px;
      font-weight: 600;
      text-align: center;
    };
}

@media (max-width: 1024px) {
  .popupTrialRegister {
    width: 80%;
  }
}

.overlayContainerPopup {
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100vh;
  background:rgba(0,0,0,0.7);
  z-index:1;
  display:none;
}

.overlayContainerPopup.active {
  display: block;
}

.containerPopup.active {
  display: block;
}


